.scroll-container--thin-scroll {
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 transparent;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: darken(#c1c1c1, 10%);
    }

    // Chrome, Edge, Safari
    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}
