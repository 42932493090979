@font-face {
  font-family: "ArialRegular";
  src: url("~assets/fonts/ArialRegular/ArialRegular.eot");
  src: url("~assets/fonts/ArialRegular/ArialRegular.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/ArialRegular/ArialRegular.woff") format("woff"), url("~assets/fonts/ArialRegular/ArialRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "ArialBold";
  src: url("~assets/fonts/ArialBold/ArialBold.eot");
  src: url("~assets/fonts/ArialBold/ArialBold.eot?#iefix") format("embedded-opentype"), url("~assets/fonts/ArialBold/ArialBold.woff") format("woff"), url("~assets/fonts/ArialBold/ArialBold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
html {
  box-sizing: border-box;
}

body {
  min-width: 720px;
  margin: 0;
  font-family: "ArialRegular", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
  background-color: #f2f2f2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
}

input,
textarea,
select,
button {
  font-weight: 500;
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: "ArialBold";
  font-weight: 400;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a.link {
  color: #999;
  transition: color 0.2s ease;
}
a.link:hover {
  color: #6f90d4;
}
a.link:active {
  color: #4b68a4;
}
a.blue-link {
  color: #6f90d4;
  text-decoration: underline;
}
a.blue-link:hover {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.selected {
  color: #0c6ad9;
}

.capitalize-first-letter {
  text-transform: lowercase;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

button {
  padding: 0;
  margin: 0;
}

.avl-btn {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "ArialBold";
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  box-shadow: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.avl-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.avl-btn--blue {
  background-color: #6f90d4;
}
.avl-btn--blue:hover {
  background-color: #8bacf0;
}
.avl-btn--blue:active {
  background-color: #4b68a4;
}
.avl-btn--blue-border {
  border: 2px solid #6f90d4;
  color: #6f90d4;
}
.avl-btn--blue-border:hover {
  background-color: #6f90d4;
  color: #fff;
}
.avl-btn--blue-border:active {
  background-color: #4b68a4;
}
.avl-btn--dark {
  background-color: #49536b;
}
.avl-btn--dark:hover {
  background-color: rgba(73, 83, 107, 0.7);
}
.avl-btn--dark:active {
  background-color: rgba(73, 83, 107, 0.7);
}
.avl-btn--dark.avl-btn--outline {
  color: #49536b;
  border-color: #49536b;
}
.avl-btn--dark.avl-btn--outline:active, .avl-btn--dark.avl-btn--outline:hover {
  color: rgba(73, 83, 107, 0.7);
  border-color: rgba(73, 83, 107, 0.7);
}
.avl-btn--red {
  background-color: #da757f;
}
.avl-btn--red:hover {
  background-color: #f18f98;
}
.avl-btn--red:active {
  background-color: #a84b54;
}
.avl-btn--bright-red {
  background-color: #be2535;
}
.avl-btn--bright-red:hover {
  background-color: rgba(190, 37, 53, 0.7);
}
.avl-btn--bright-red:active {
  background-color: rgba(190, 37, 53, 0.8);
}
.avl-btn--green {
  background-color: #8ac291;
}
.avl-btn--green:hover {
  background-color: rgba(138, 194, 145, 0.7);
}
.avl-btn--dark-green {
  background-color: #5ac39f;
}
.avl-btn--dark-green:hover {
  background-color: rgba(90, 195, 159, 0.7);
}
.avl-btn--dark-green:active {
  background-color: #5ac39f;
}
.avl-btn--purple {
  background-color: #724496;
}
.avl-btn--purple:hover {
  background-color: rgba(114, 68, 150, 0.7);
}
.avl-btn--dark-purple {
  background-color: #3e1e7d;
}
.avl-btn--dark-purple:hover {
  background-color: rgba(62, 30, 125, 0.7);
}
.avl-btn--dark-blue {
  background-color: #0f4d97;
}
.avl-btn--dark-blue:hover {
  background-color: rgba(15, 77, 151, 0.7);
}
.avl-btn--yellow {
  background-color: #f0c234;
}
.avl-btn--yellow:hover {
  background-color: #f0d687;
}
.avl-btn--yellow:active {
  background-color: #f0c234;
}
.avl-btn--notice-yellow {
  background-color: #ec9c42;
}
.avl-btn--notice-yellow:hover {
  background-color: rgba(236, 156, 66, 0.7);
}
.avl-btn--notice-yellow:active {
  background-color: #ec9c42;
}
.avl-btn--gray {
  background-color: #ccc;
}
.avl-btn--gray:hover {
  background-color: rgba(204, 204, 204, 0.7);
}
.avl-btn--mid-gray {
  background-color: #f2f2f2;
  color: #ccc;
}
.avl-btn--mid-gray:hover {
  background-color: rgba(242, 242, 242, 0.7);
}
.avl-btn--wide {
  width: 100%;
}
.avl-btn--large {
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
}
.avl-btn .avl-btn-spinner {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  margin-left: -22px;
  vertical-align: middle;
  text-indent: -9999px;
  animation: 0.35s linear infinite btn-spin;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 100%;
  overflow: hidden;
  opacity: 0;
}
.avl-btn.is-loading .avl-btn-spinner {
  margin-left: 10px;
  transition: 0.2s margin ease-in, 0.4s opacity ease-in;
  opacity: 1;
}
.avl-btn--outline {
  background: none;
  border: 2px solid;
  box-sizing: border-box;
}
.avl-btn--outline:hover, .avl-btn--outline:active {
  background: none;
}

.pulsed-button {
  box-shadow: 0 0 0 rgba(73, 83, 107, 0.4);
  animation: 1.8s linear infinite btn-pulse;
  animation-delay: 0.4s;
}

.clear-btn-styles {
  all: unset;
  cursor: pointer;
}

@keyframes btn-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(73, 83, 107, 0.6);
    background-color: #49536b;
  }
  20% {
    box-shadow: 0 0 0 0 rgba(73, 83, 107, 0.6);
    background-color: #49536b;
  }
  30% {
    box-shadow: 0 0 0 0 rgba(73, 83, 107, 0.6);
    background-color: rgba(73, 83, 107, 0.8);
  }
  50% {
    box-shadow: 0 0 0 20px rgba(73, 83, 107, 0);
    background-color: #49536b;
  }
  70% {
    box-shadow: 0 0 0 20px rgba(73, 83, 107, 0);
    background-color: #49536b;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(73, 83, 107, 0);
    background-color: #49536b;
  }
}
@keyframes btn-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cdk-overlay-dark-backdrop {
  background-color: rgba(51, 51, 51, 0.85);
}

.notice-folder-details-dialog .avl-btn {
  background-color: #ec9c42;
}
.notice-folder-details-dialog .avl-btn:hover {
  background-color: rgba(236, 156, 66, 0.7);
}
.notice-folder-details-dialog .form-control:focus {
  box-shadow: inset 0 0 0 1px rgba(236, 156, 66, 0.4);
  border-color: rgba(236, 156, 66, 0.5);
}
.notice-folder-details-dialog .form-control:hover {
  border-color: rgba(236, 156, 66, 0.7);
}

.mat-dialog-title {
  padding: 16px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: #999;
  border-bottom: 1px solid #eaeaea;
}

.mat-dialog-content {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mat-dialog-actions {
  margin-bottom: 0 !important;
}

.report-dialog .mat-dialog-container,
.confirm-dialog .mat-dialog-container,
.options-dialog .mat-dialog-container,
.error-alert-dialog .mat-dialog-container,
.full-screen-dialog .mat-dialog-container,
.notification-center .mat-dialog-container,
.schedule-preview-dialog .mat-dialog-container {
  position: relative;
  padding: 0;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(2, 3, 3, 0.25);
}

.snackbar-view.mat-snack-bar-container {
  max-width: 100%;
  padding: 0;
  margin: 50px;
  background: transparent;
  box-shadow: none;
}

.schedule-preview-dialog h4 {
  margin: 0;
}

.report-dialog .dialog__close,
.error-alert-dialog .dialog__close,
.notification-center .dialog__close {
  top: 12px;
  right: 15px;
}

.notification-center .mat-dialog-container {
  margin: 0 128px;
  background: transparent;
  box-shadow: none;
  overflow: hidden;
}

.dialog__close, .dialog__left-top-btn {
  position: absolute;
  top: 14px;
  right: 18px;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 0;
  transition: all 0.2s ease;
  cursor: pointer;
}
.dialog__close .close-icon, .dialog__left-top-btn .close-icon {
  opacity: 0.6;
  transition: opacity 0.2s ease;
}
.dialog__close:hover, .dialog__left-top-btn:hover {
  background-color: rgba(204, 204, 204, 0.3);
}
.dialog__close:hover .close-icon, .dialog__left-top-btn:hover .close-icon {
  opacity: 1;
}
.dialog__close:active, .dialog__left-top-btn:active {
  background-color: rgba(204, 204, 204, 0.5);
}
.dialog__bottom-btn {
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 0;
}
.dialog__left-top-btn {
  left: 18px;
}

.full-screen-dialog {
  width: 100%;
}

.filed-copies-dialog {
  width: 68%;
  height: 600px;
}
.filed-copies-dialog .mat-dialog-title {
  text-align: center;
  margin-bottom: 32px;
}
.filed-copies-dialog .mat-dialog-container {
  position: relative;
  padding: 0;
}
.filed-copies-dialog .mat-dialog-content {
  flex-grow: 1;
  padding: 10px 0 0 0;
}

.dialog-checkbox {
  margin-left: 24px;
}
.dialog-checkbox .mat-checkbox-layout .mat-checkbox-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #6f90d4;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #6f90d4;
}

.border-radius-8px .mat-dialog-container {
  border-radius: 8px;
}

@media (max-width: 992px) {
  .notification-center .mat-dialog-container {
    margin: 0 64px;
  }
}
@media (max-width: 768px) {
  .notification-center .mat-dialog-container {
    margin: 0 32px;
  }
}
.radio-option {
  margin: 0 4px;
}

.radio-option-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 110px;
  padding-top: 16px;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0.6;
  filter: grayscale(1);
}
.radio-option-label:hover {
  background-color: rgba(204, 204, 204, 0.15);
}

.radio-option-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.radio-option-input:checked + .radio-option-label {
  background-color: rgba(204, 204, 204, 0.15);
  opacity: 1;
  filter: grayscale(0);
}
.radio-option-input:disabled + .radio-option-label {
  background-color: #fff;
  opacity: 0.3;
  cursor: default;
}

.radio-option-icon {
  width: 60px;
  pointer-events: none;
}

.radio-option-name {
  margin-top: 9px;
  font-family: "ArialBold", serif;
  color: #333;
  font-size: 11px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-expansion-panel-header {
  position: relative;
  font-family: "ArialRegular";
  font-size: 12px;
}
.mat-expansion-panel-header .mat-content {
  align-items: center;
}
.mat-expansion-panel-header .mat-expansion-indicator {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
}
.mat-expansion-panel-header .mat-expansion-indicator:after {
  content: none;
  border-style: none;
}
.mat-expansion-panel-header .mat-expansion-indicator:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url("~assets/icons/icon_dropdown.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mat-expansion-panel-header-description {
  position: relative;
  color: #fff;
}

.mat-expansion-panel-content {
  font-family: "ArialRegular";
}
.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0;
}
.mat-expansion-panel-content .mat-expansion-panel-body .mat-expansion-panel-header-description {
  margin: 0;
}

.mat-expansion-panel-header-description {
  align-items: center;
  padding: 10px 24px;
  background-color: rgba(196, 105, 114, 0.6);
}

.mat-menu-panel {
  min-height: 32px !important;
  box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
}
.mat-menu-panel .mat-menu-content:not(:empty) {
  padding: 0;
}
.mat-menu-panel .mat-menu-item {
  font-family: "ArialRegular";
  height: 32px;
  line-height: 32px;
  color: #999;
  transition: all 0.2s ease;
}
.mat-menu-panel .mat-menu-item:hover {
  color: #333;
}

.profile-menu {
  padding: 7px 6px;
  margin: 17px -10px 0 0;
}
.profile-menu.mat-menu-panel {
  min-width: 180px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(38, 50, 56, 0.24);
  overflow: hidden;
}
.profile-menu .mat-menu-item {
  font-size: 12px;
  line-height: 21px;
  color: #96969e;
}
@media (max-width: 992px) {
  .profile-menu {
    right: 55px;
  }
}
@media (max-width: 768px) {
  .profile-menu {
    right: 22px;
  }
  .profile-menu__arrow {
    display: none;
  }
}

.mat-sort-header-container .mat-sort-header-button {
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mat-paginator-range-label {
  font-family: "ArialRegular";
  color: #333;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.mat-paginator {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mat-paginator .mat-button-ripple.mat-ripple {
  border-radius: 3px;
}
.mat-paginator.hidden {
  display: none;
}

.cdk-drag-preview {
  opacity: 1 !important;
  background-color: white;
  font-size: 13px;
  color: #000113;
}

.drag-drop__preview {
  display: flex;
  align-items: center;
}
.drag-drop__placeholder {
  background-color: #ccc;
  transition: transform 250ms linear;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #6f90d4;
}

.page-spinner__loading {
  margin: auto;
}
.page-spinner__loading-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100vh;
}

.alert {
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.12px;
}

.alert-error {
  background-color: #da757f;
}
.alert-error::before {
  content: "";
  width: 24px;
  height: 14px;
  display: block;
  margin-right: 12px;
  background: url("~assets/images/login/icon_alert_white.png") no-repeat top;
  background-size: 15px 14px;
}

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}
.form-group .form-icon {
  position: absolute;
  top: 20px;
  left: 24px;
  display: block;
  width: 16px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.2;
  transition: opacity 0.2s ease;
}
.form-group.has-error .form-control {
  box-shadow: inset 0 0px 0px 1px #da757f;
  border-color: #da757f;
}
.form-group.has-error::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 24px;
  display: block;
  width: 15px;
  height: 15px;
  background: url("~assets/images/login/icon_alert.png") no-repeat center;
  background-size: contain;
}
.form-group.has-error .form-icon {
  opacity: 1;
}
.form-group.has-error .error-message {
  display: block;
}
.form-group .error-message {
  display: none;
  color: #da757f;
  font-family: "ArialBold";
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.form-control {
  width: 100%;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  line-height: 16px;
  letter-spacing: 0.14px;
  transition: all 0.2s ease;
}
.form-control:-moz-placeholder {
  color: #ccc;
}
.form-control::-moz-placeholder {
  color: #ccc;
}
.form-control:-ms-input-placeholder {
  color: #ccc;
}
.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.form-control:hover {
  border-color: rgba(0, 0, 0, 0.5);
}
.form-control:focus {
  box-shadow: inset 0 0px 0px 1px #6f90d4;
  border-color: #6f90d4;
}
.form-control:focus ~ .form-icon {
  opacity: 1;
}

.form-options a {
  line-height: 22px;
  color: #999;
  font-size: 12px;
}
.form-options a:hover {
  color: #6f90d4;
}

.auth-page {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.auth-page__left-column {
  display: flex;
  justify-content: center;
  width: 51%;
  background-image: url("~assets/images/login/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 720px) {
  .auth-page__left-column {
    display: none;
  }
}
.auth-page__left-column-authenticate {
  display: flex;
  justify-content: center;
  width: 51%;
  background-image: url("~assets/images/login/login-bg-authenticate.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 720px) {
  .auth-page__left-column-authenticate {
    display: none;
  }
}
.auth-page__title {
  width: 100%;
  max-width: 329px;
  color: #6f90d4;
  font-family: "Arial", serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  margin: 28.6% 9.4% 0 0;
}
.auth-page__right-column {
  width: 49%;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 16px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
@media (max-width: 720px) {
  .auth-page__right-column {
    width: 100%;
  }
}
.auth-page__links {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 28px 37px 28px 34px;
}
.auth-page__link-item {
  font-family: "Arial", serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #6f90d4;
}
.auth-page__form-wrap {
  width: 100%;
  max-width: 293px;
  margin-top: 13.75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
}
.auth-page__form-wrap mat-icon[svgIcon=logo] {
  width: 80px;
  height: 32px;
  margin: 0 auto 53px auto;
}
.auth-page__form-wrap .auth-page__form {
  width: 293px;
}
.auth-page__form-wrap .auth-page__form button {
  height: 40px;
  padding: 0;
  text-transform: uppercase;
}
.auth-page__form-wrap .auth-page__form button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
.auth-page__form-wrap .auth-page__form .form-field {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}
.auth-page__form-wrap .auth-page__form .form-field.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
}
.auth-page__form-wrap .auth-page__form .form-field label {
  display: inline-block;
  font-family: "Arial", serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333;
  margin-bottom: 10px;
}
.auth-page__form-wrap .auth-page__form .form-field a {
  font-family: "Arial", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6f90d4;
}
.auth-page__form-wrap .auth-page__form .form-field a:active, .auth-page__form-wrap .auth-page__form .form-field a:focus {
  outline-style: none;
  outline-width: 0 !important;
  outline-color: unset !important;
}
.auth-page__form-wrap .auth-page__form .form-field a:active::-moz-focus-inner, .auth-page__form-wrap .auth-page__form .form-field a:focus::-moz-focus-inner {
  border: 0 !important;
}
.auth-page__form-wrap .auth-page__form .form-field span {
  color: #999;
  font-family: "Arial", serif;
  font-size: 14px;
  line-height: 18px;
  padding-right: 8px;
}
.auth-page__form-wrap .auth-page__form .form-field mat-icon {
  position: absolute;
  top: 35px;
  right: 10px;
  height: 20px;
  width: 25px;
}
.auth-page__form-wrap .auth-page__form .form-field .mat-error {
  position: absolute;
  color: #da757f;
  font-family: Arial, serif;
  font-size: 12px;
  line-height: 16px;
  bottom: -17px;
  left: 11px;
}
.auth-page__form-wrap .auth-page__form .form-field .button {
  cursor: pointer;
}
.auth-page__form-wrap .auth-page__form .form-field input {
  background: #fff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
}
.auth-page__form-wrap .auth-page__form .form-field input:focus {
  border-color: #8bacf0;
}
.auth-page__form-wrap .auth-page__form .form-field input:-webkit-autofill, .auth-page__form-wrap .auth-page__form .form-field input:-webkit-autofill:hover, .auth-page__form-wrap .auth-page__form .form-field input:-webkit-autofill:focus, .auth-page__form-wrap .auth-page__form .form-field input:-webkit-autofill:active {
  box-shadow: 0 0 0 50px #fff inset;
  -webkit-box-shadow: 0 0 0 50px #fff inset;
}
.auth-page__form-wrap .auth-page__form .form-field input::placeholder {
  font-family: "Arial", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #999;
  width: 100%;
}
.auth-page__form-wrap .auth-page__form .form-field input.error {
  border-color: #da757f;
}
.auth-page__form-title {
  width: 100%;
  margin-bottom: 53px;
}
.auth-page__form-title h2 {
  font-family: "Arial", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #6f90d4;
}
.auth-page__sso-auth {
  display: flex;
  flex-direction: column;
  gap: 38px;
  align-items: center;
}

.info-form__link {
  width: 100%;
}
.info-form__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: margin-top 0.2s;
}
.info-form__wrap.with-margin {
  margin-top: 20%;
}
.info-form__wrap .mat-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
}
.info-form__wrap h2 {
  font-family: "Arial", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #6f90d4;
  margin-bottom: 43px;
  width: 100%;
  text-align: center;
}
.info-form__wrap h2.error {
  color: #da757f;
}
.info-form__wrap h3 {
  font-family: "Arial", serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  margin-bottom: 54px;
}
.info-form__wrap button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: #6f90d4;
  border: none;
  font-family: Arial, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  cursor: pointer;
  margin-top: -11px;
  text-transform: uppercase;
}
.info-form__wrap button.error {
  background-color: #da757f;
  margin-top: 0;
}

.global-onboarding__elevate {
  position: relative;
  z-index: 1001;
}
.global-onboarding__elevate td {
  z-index: 1002;
  border: none !important;
  background: #fff;
}
.global-onboarding__elevate.new:first-child:after {
  z-index: 1003;
}
.global-onboarding__elevate.new:first-child:before {
  z-index: 1004;
}
.global-onboarding__backdrop {
  background-color: rgba(51, 51, 51, 0.7);
  opacity: 0.7;
}
.global-onboarding__panel {
  z-index: auto;
}

.cdk-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  min-width: 720px;
  z-index: auto !important;
}

.cdk-global-scrollblock {
  position: absolute;
  overflow: hidden !important;
}

.loading-svg {
  position: relative;
}
.loading-svg__circle {
  stroke: #bbbbbb;
  animation: movePart 0.5s linear;
  transform-origin: center;
  transform: rotate(-90deg);
}
.file-over .loading-svg__circle {
  stroke: rgba(236, 156, 66, 0.3);
}
.uploading .loading-svg__circle {
  stroke: rgba(236, 156, 66, 0.3);
}
.loading-svg__animated-circle {
  stroke: #5ac39f;
  transform-origin: center;
  opacity: 0;
}
.completed .loading-svg__animated-circle {
  opacity: 1;
  stroke-dasharray: 144;
  stroke-dashoffset: 0;
  animation: movePart ease 0.5s forwards;
  transform: rotate(270deg);
}
.loading-svg__part {
  stroke: #ec9c42;
  transform-origin: center;
  stroke-dasharray: 40;
  stroke-dashoffset: 10;
  opacity: 0;
  transform: rotate(-90deg);
}
.uploading .loading-svg__part {
  opacity: 1;
  animation: dash 2s linear infinite;
}
.completed .loading-svg__part {
  display: none;
  opacity: 0;
}
.loading-svg__down {
  fill: #bbb;
}
.file-over .loading-svg__down {
  fill: #ec9c42;
}
.uploading .loading-svg__down {
  fill: #ec9c42;
}
.completed .loading-svg__down {
  display: none;
}
.loading-svg__arrow {
  fill: #bbb;
}
.file-over .loading-svg__arrow {
  fill: #ec9c42;
  animation: moveArrow 1s ease infinite;
}
.uploading .loading-svg__arrow {
  fill: #ec9c42;
  animation: moveArrow 1s ease infinite;
}
.completed .loading-svg__arrow {
  display: none;
}
.loading-svg__tick {
  stroke: #5ac39f;
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  transform: translateY(2px);
}
.completed .loading-svg__tick {
  animation: moveTick 0.5s ease forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 10;
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 0;
    transform: rotate(260deg);
  }
  80% {
    stroke-dashoffset: 40;
    transform: rotate(260deg);
  }
  90% {
    stroke-dashoffset: 40;
    transform: rotate(356deg);
  }
  100% {
    stroke-dashoffset: 90;
    transform: rotate(360deg);
  }
}
@keyframes moveArrow {
  50% {
    transform: translate(0, 10%);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes movePart {
  0% {
    stroke-dashoffset: 144;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes moveTick {
  0% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.mat-progress-bar-fill::after {
  background-color: #6f90d4;
}
.green .mat-progress-bar-fill::after {
  background-color: #5ac39f;
}
.yellow .mat-progress-bar-fill::after {
  background-color: #f0c234;
}
.notice-yellow .mat-progress-bar-fill::after {
  background-color: #ec9c42;
}

.mat-progress-bar-buffer {
  background: #fff;
}
.yellow .mat-progress-bar-buffer {
  background-color: #f0d687;
}
.blue .mat-progress-bar-buffer {
  background-color: #8bacf0;
}

.avl-h2 {
  font-size: 20px;
}

.collection__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
}
.collection__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.collection__title {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #999;
}
.collection__table-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.collection__table-container .mat-paginator-container {
  padding: 0 24px;
}
.collection__table-wrap {
  overflow-x: auto;
  flex: 1;
}
.collection__table {
  width: 100%;
  font-family: "ArialRegular";
}
.collection__table tr.mat-header-row {
  height: 44px;
  text-transform: uppercase;
}
.collection__table .mat-header-cell {
  color: #999;
  font-size: 10px;
  letter-spacing: 1px;
}
.collection__table th.mat-header-cell,
.collection__table td.mat-cell {
  padding-right: 16px;
  padding-left: 16px;
  border-bottom-color: rgba(204, 204, 204, 0.3);
}
.collection__table th.mat-header-cell.sortable,
.collection__table td.mat-cell.sortable {
  background: rgba(0, 0, 0, 0.04);
}
.collection__table td.mat-cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
.collection__table .checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.collection__add-all-control {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.collection__add-all-control .checkbox-input:checked ~ .mat-icon .add-all-box,
.collection__add-all-control .checkbox-input:checked ~ .mat-icon .add-all-check {
  fill: #724496;
}
.collection__add-all-control .checkbox-input:checked ~ .mat-icon .add-all-check {
  opacity: 1;
}
.collection__add-all-control .checkbox-input:checked ~ .checkbox-text {
  color: #724496;
}
.collection__add-all-control .mat-icon {
  width: 20px;
  height: 20px;
}
.collection__add-all-control .mat-icon .add-all-box,
.collection__add-all-control .mat-icon .add-all-check {
  fill: #999;
  transition: all 0.2s linear;
}
.collection__add-all-control .mat-icon .add-all-check {
  opacity: 0;
}
.collection__add-all-control .checkbox-text {
  margin-left: 8px;
  color: #999;
  font-size: 14px;
  letter-spacing: 0.1px;
}
.collection__add-control .checkbox-input:checked ~ .collection__add-label {
  background-color: #5ac39f;
}
.collection__add-control .checkbox-input:checked ~ .collection__add-label:before {
  opacity: 0;
}
.collection__add-control .checkbox-input:checked ~ .collection__add-label:after {
  opacity: 1;
}
.collection__add-label {
  position: relative;
  max-width: 88px;
  height: 32px;
  background-color: #6f90d4;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.collection__add-label:hover {
  background-color: #4b68a4;
}
.collection__add-label:before {
  content: "Add";
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 0.2s linear;
}
.collection__add-label:after {
  content: "";
  width: 18px;
  height: 15px;
  display: block;
  position: absolute;
  top: 8px;
  left: 50%;
  background-image: url("~assets/icons/icon_small_white-check.svg");
  background-repeat: no-repeat;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.2s linear;
}

.mat-snack-bar-container {
  background: rgba(38, 50, 56, 0.9);
  color: #fff;
  box-shadow: 0 4px 8px rgba(84, 110, 122, 0.24);
}
.mat-snack-bar-container.files-uploaded-snackbar, .mat-snack-bar-container.info-snackbar {
  position: absolute;
  margin: 0;
  bottom: 130px;
  right: 0;
  left: 50%;
  transform: translateX(-50%) !important;
}
.mat-snack-bar-container.min-content {
  width: min-content;
}

.error-alert {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 7px 7px 24px;
  background-color: #da757f;
  border-radius: 5px;
}
.error-alert__wrap {
  position: relative;
  height: 45px;
}
.error-alert--single:hover .alert-animated-icon {
  opacity: 0;
  visibility: hidden;
}
.error-alert--single:hover .download-icon {
  opacity: 1;
  visibility: visible;
}
.error-alert__text {
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  max-width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.error-alert__close-btn {
  margin-left: auto;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.error-alert__multiple {
  position: relative;
  height: 45px;
}
.error-alert__multiple-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  margin-bottom: 15px;
}
.error-alert__remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.error-alert__icon {
  position: relative;
  height: 24px;
  width: 24px;
}
.error-alert__icon .alert-animated-icon,
.error-alert__icon .download-icon {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
}

.mat-expansion-panel {
  background-color: #da757f;
  color: #fff;
}

.mat-expansion-panel-header-description {
  transition: all 0.2s ease;
}
.mat-expansion-panel-header-description:hover {
  background-color: #a84b54;
}
.mat-expansion-panel-header-description:hover .alert-animated-icon {
  opacity: 0;
  visibility: hidden;
}
.mat-expansion-panel-header-description:hover .download-icon {
  opacity: 1;
  visibility: visible;
}

.alert-animated-icon {
  opacity: 1;
  visibility: visible;
}

.download-icon {
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.download-icon:hover {
  background-color: rgba(204, 204, 204, 0.4);
  box-shadow: 0 0 0 5px rgba(204, 204, 204, 0.4);
}

.mat-tooltip {
  max-width: 166px !important;
  background: rgba(38, 50, 56, 0.9);
  padding: 12px !important;
  margin: 8px !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.mat-tooltip__bright-without-opacity {
  background-color: #4c5263;
  border-radius: 5px !important;
  padding: 8px 12px !important;
}

.title-search-registry-select.mat-select {
  display: flex;
  align-items: center;
  width: 262px;
  height: 32px;
  padding: 0 16px;
  border-radius: 2px;
  background-color: #f4f4f5;
}
.title-search-registry-select .mat-select-arrow {
  color: #96969e !important;
}

.mat-select-panel.expanded {
  max-height: 290px;
}
.mat-select-panel.search-registry-select-panel {
  min-width: calc(100% + 24px) !important;
  transform: translateX(4px) !important;
}
.mat-select-panel .mat-option {
  background-color: transparent;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: transparent;
  color: #333;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background-color: transparent;
}

.scroll-container--thin-scroll {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 transparent;
  overflow: auto;
}
.scroll-container--thin-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}
.scroll-container--thin-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.scroll-container--thin-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.scroll-container--thin-scroll::-webkit-scrollbar-track {
  background: transparent;
}